import React from "react";
import styled from "styled-components";
import HeroWrap from "../layout/heroWrap";
import SectionWrap from "../layout/sectionWrap";
import SectionContent from "../layout/sectionContent";
import Spacer from "../layout/spacer";
import FootContent from "../layout/footContent";
import LeadLabel from "../layout/leadLabel";
import ContactIcon from "../assets/icons/contact.svg";
import ContactCards from "../cards/contactCards";



const ContactBody = styled.p`
    margin-bottom: ${props => props.theme.space.s7};
`;

function Contact() {
  return (
    <div>
      <HeroWrap>
        <small></small>
        <FootContent>
          <LeadLabel>
            <p>Contact me</p>
          </LeadLabel>
          <h1>If you’d like to <b>work together</b>, Let’s talk</h1>
        </FootContent>
      </HeroWrap>
      <SectionWrap>
        <SectionContent>
          <h2>Get in touch</h2>
          <ContactCards />
        </SectionContent>
      </SectionWrap>

    </div>
  );
}

export default Contact;