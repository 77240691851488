import styled from "styled-components";
import device from "../global/mediaQueries";

const HeroContent = styled.div`
  display: flex;
  align-items: left;
  flex-direction:column;
  gap: ${(props) => props.theme.space.s7};
  max-width: 1200px;
  width:100%;

  h1 {
    font-weight: 400;
    font-size: 64px;
    line-height: 82px;
    color: ${(props) => props.theme.colors.lightGreyInverted};
   
    b {
      font-weight: 600;
      color: ${(props) => props.theme.colors.white};
    }
    @media ${device.laptop} {
      font-size: 44px;
      line-height: 48px;
    }
  }
  h2, h3, h4, h5, p {
    color: ${(props) => props.theme.colors.white};
    font-weight:normal;

    span {
      color: ${(props) => props.theme.colors.lightGreyInverted};
    }
  }

@media ${device.laptop} {
  border: none;
  border-radius: 0;
  padding: ${(props) => props.theme.space.s8} ${(props) => props.theme.space.s6};
  box-shadow: none;
}

  
`;

export default HeroContent;
