import styled from "styled-components";
import device from "../global/mediaQueries";

import Mail from "../assets/icons/mail.svg";
import Linked from "../assets/icons/linkedinBlack.svg";

const ContactCardsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: stretch;
  width: 100%;
  gap: 32px;
  margin-top: ${(props) => props.theme.space.s6};
`;

const ContactCard = styled.div`
  display: flex;
  gap:24px;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(50% - 16px);
  padding: ${(props) => props.theme.space.s8} ${(props) => props.theme.space.s6};
  border: 1px solid ${(props) => props.theme.colors.keyline};
  border-radius: 16px;

    img {
      width:44px;
      height:44px;
    } 
    p {
      margin:8px 0 0 0;
    }
    a {
      text-decoration: none;
    }
    @media ${device.laptop} {
      flex-direction: column;
      gap:16px;
      width: 100%;
      padding: ${(props) => props.theme.space.s8} ${(props) => props.theme.space.s4};
    }
  }

`;

const ContactCards = () => {
  return (
    <div>
      <ContactCardsWrap>
        <ContactCard>
          <img src={Mail} alt='Jagex logo' />
          <div>
            <h3>Send me an email</h3>
            <p><span>thomasweaverdesign@gmail.com</span></p>
          </div>
        </ContactCard>
        <ContactCard>
          <img src={Linked} alt='Jagex logo' />
          <div>
            <h3>Find me on LinkedIn</h3>
            <p><a href="https://www.linkedin.com/in/thomas-weaver-design"><span>in/thomas-weaver-design</span></a></p>
          </div>
        </ContactCard>
      </ContactCardsWrap>
    </div>
  );
};


export default ContactCards;
