import styled from "styled-components";
import device from "../global/mediaQueries";

const HeroWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 200px 80px 140px 80px;
  background: ${(props) => props.theme.colors.black};

  small {
    max-width: 1200px;
    width: 100%;
    color: ${(props) => props.theme.colors.black};
  }

  @media ${device.laptop} {
    padding: 68px 0 0 0px;
  }
`;

export default HeroWrap;
