import styled from "styled-components";
import { Link } from "react-router-dom";
import device from "../global/mediaQueries";

const SecondaryBtnWrap = styled(Link)`
  display: flex;
  height: 48px;
  max-width:180px;
  min-width:160px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.accentInverted};
  border-radius: 8px;
  transition: all 0.5s;
  text-decoration: none;
  padding: ${(props) => props.theme.space.s4} ${(props) => props.theme.space.s6};

  p {
    text-align: centre;
    margin: 0;
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.lightGreyInverted};
  }
  @media ${device.mobileL} {
      flex-grow:1;
      min-width:auto;

  }
`;

const SecondaryBtn = ({ label, page }) => {
  return (
    <SecondaryBtnWrap to={page}>
      <p>{label}</p>
    </SecondaryBtnWrap>
  );
};

export default SecondaryBtn;
