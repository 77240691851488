import styled from "styled-components";
import device from "../global/mediaQueries";

const LeadLabel = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;

  h5,
  h4,
  p {
    display: block;
    margin: 0 0 0 ${props => props.theme.space.s3};
    color: ${(props) => props.theme.colors.white};
  }
  &::before {
    content:"";
    width: ${props => props.theme.space.s5};
    height: 1px;
    background: ${(props) => props.theme.colors.accentInverted};
  }
`;

export default LeadLabel;
