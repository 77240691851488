import styled from "styled-components";
import HeroContent from "../layout/heroContent";
import device from "../global/mediaQueries";

const FootContent = styled(HeroContent)`
  h1 {
    font-weight: 400;
    font-size: 64px;
    line-height: 82px;
    color: ${(props) => props.theme.colors.lightGreyInverted};
   
    b {
      font-weight: 600;
      color: ${(props) => props.theme.colors.white};
    }
    @media ${device.laptop} {
      font-size: 44px;
      line-height: 48px;
    }
  }
  p {
    color: ${(props) => props.theme.colors.white};
  }
  ul {
    li {
      margin: 0px ${(props) => props.theme.space.s2} 0 0;

      p:hover {
        &::after {
          width: 0%;
        }
      }
    }
  }
`;

export default FootContent;
